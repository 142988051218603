import React, { Fragment, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import {
  ParameterValues,
  ReportingMethod,
  ReportStepper,
  SelectReportingMethod,
  TStepItem
} from '@/components';
import { BarChartIcon, ExelIcon, SettingsIcon, SheetsIcon } from '@/components/icons';
import ChartBreakoutIcon from '../../components/icons/ChartBreakout';
import ReportTemplates from '@/pages/CreateNewReportPage/ReportTemplates';
import ArrowNarrowLeft from '@/components/icons/ArrowNarrowLeft';
import FileQuestionIcon from '@/components/icons/FileQuestion';
import useSession from '@/hooks/useSession';
import SelectReportingMethodExcel from '@/components/selectReportingMethod/selectReportingMethodExcel';
import { ReportWrraperProps } from './type';
import { ReportTemplateApiTab, ReportTemplateContent } from '@/services/api/reportTemplates/types';
import { ParameterValueApply, TemplateParameterWithValue } from '../parameterValues/type';
import { SelectReportMethodForm } from '../selectReportingMethod/SelectReportingMethod';
import { ConnectionForCreateReport } from '@/pages/CreateNewReportPage/ConnectionForCreateReport';
import { CompanyRowI } from '@/hooks/companyService/type';
import {
  mapOnFinishRepostTemplateData,
  mapParameterWithValuesToRecords,
  prepareTemplateParameters
} from '@/components/report/helpers';
import { REPORT_STEPS } from '@/components/report/const';
import Scheduling from '../icons/Scheduling';

const steps: TStepItem[] = [
  { label: 'Connection', icon: <BarChartIcon /> },
  { label: 'Report template', icon: <BarChartIcon /> },
  { label: 'Report settings', icon: <SettingsIcon /> },
  { label: 'Report format', icon: <ChartBreakoutIcon /> },
  { label: 'Schedule', icon: <Scheduling /> }
];

function ReportWrraper({
  reportStepperStyle,
  stepsStyle,
  styleConfig,
  onApplyGoogleSheets,
  onApplyExcel,
  startStep = REPORT_STEPS.CONNECTIONS,
  isShowHeader = true
}: ReportWrraperProps): React.JSX.Element {
  const { user } = useSession();

  const [activeStep, setActiveStep] = useState(startStep);
  const [selectedTemplate, setSelectedTemplate] = useState<ReportTemplateContent | undefined>(
    undefined
  );
  const [parameterValues, setParameterValues] = useState<ParameterValueApply | undefined>();
  const [parameterTabValues, setParameterTabValues] = useState<ReportTemplateApiTab[]>([]);
  const [connection, setConnection] = useState<CompanyRowI | undefined>();

  const parametersValuesRender = useMemo(() => {
    if (parameterValues?.parameters) {
      return mapParameterWithValuesToRecords(parameterValues.parameters);
    }
    return prepareTemplateParameters(selectedTemplate?.templateParameters || []);
  }, [parameterValues, selectedTemplate]);

  const parametersTabsValuesRender = useMemo(() => {
    if (parameterTabValues.length) {
      return parameterTabValues;
    }

    return selectedTemplate?.reportTemplateApiTabs.map(tab => {
      return {
        ...tab,
        templateParametersValuesRender: prepareTemplateParameters(tab.templateParameters)
      };
    });
  }, [parameterValues, selectedTemplate]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSelectConnection = (value: CompanyRowI) => {
    setConnection(value);
    handleNext();
  };

  const handleSelectTemplate = (template: ReportTemplateContent) => {
    setSelectedTemplate(template);
    handleNext();
  };

  const handleApplyParameterValues = (
    values: ParameterValueApply,
    parameters: TemplateParameterWithValue[],
    parameterValueTabs: ReportTemplateApiTab[]
  ) => {
    setParameterValues({
      ...values,
      parameters
    });
    setParameterTabValues(parameterValueTabs);
    handleNext();
  };

  const handleApplyGoogleSheets = (value: SelectReportMethodForm) => {
    const reBody = mapOnFinishRepostTemplateData(value, {
      template: selectedTemplate,
      parameterValues,
      parameterTabValues
    });
    onApplyGoogleSheets && onApplyGoogleSheets(reBody);
  };

  const handleApplyExcel = (value: SelectReportMethodForm) => {
    const reBody = mapOnFinishRepostTemplateData(
      { ...value, email: user?.email },
      {
        template: selectedTemplate,
        parameterValues,
        parameterTabValues
      }
    );
    onApplyExcel && onApplyExcel(reBody);
  };

  return (
    <>
      {isShowHeader && (
        <ReportStepper steps={steps} activeStep={activeStep} containerStyle={reportStepperStyle} />
      )}
      <Fragment>
        {activeStep === REPORT_STEPS.CONNECTIONS && (
          <ConnectionForCreateReport
            onNext={handleSelectConnection}
          />
        )}
        {activeStep === REPORT_STEPS.REPORT && (
          <ReportTemplates
            tableStyle={stepsStyle?.stepOne}
            styleConfig={styleConfig}
            onSelectTemplate={handleSelectTemplate}
            connection={connection}
            onBack={handleBack}
          />
        )}
        {activeStep === REPORT_STEPS.PARAMETER_VALUES && (
          <ParameterValues
            onBack={handleBack}
            isBack={true}
            // isBack={isBackButtonInSecondStep}
            styleConfig={styleConfig}
            defaultName={parameterValues?.reportName || selectedTemplate?.name}
            onApply={handleApplyParameterValues}
            renderParameter={parametersValuesRender}
            parametersTabsValuesRender={parametersTabsValuesRender}
          />
        )}
        {activeStep === REPORT_STEPS.REPORTING_METHOD && (
          <Box
            sx={{
              mt: styleConfig?.isNotMarginTop ? '0' : '2rem',
              ...(stepsStyle?.stepTwo?.container || {})
            }}
          >
            <ReportingMethod
              styleBlock={stepsStyle?.stepTwo?.block}
              title="Select format"
              titleIcon={<FileQuestionIcon fill="#0078C8" />}
              description="Please select a format to continue"
              onBackClick={handleBack}
              actions={[
                {
                  icon: <SheetsIcon />,
                  label: 'Google Sheets',
                  onClick: () => {
                    handleNext();
                  }
                  // disabled: true
                },
                {
                  icon: <ExelIcon />,
                  label: 'Microsoft Excel',
                  onClick: () => {
                    setActiveStep(REPORT_STEPS.EXCEL);
                  }
                }
              ]}
            />
          </Box>
        )}
        {activeStep === REPORT_STEPS.GOOGLE_SHEETS && (
          <SelectReportingMethod
            styleConfig={styleConfig}
            userName={user?.nickname || ''}
            email={user?.email || ''}
            avatarUrl={user?.picture || ''}
            onBack={handleBack}
            onApply={handleApplyGoogleSheets}
            company={connection}
          />
        )}
        {activeStep === REPORT_STEPS.EXCEL && (
          <SelectReportingMethodExcel
            styleConfig={styleConfig}
            userName={user?.nickname || ''}
            email={user?.email || ''}
            avatarUrl={user?.picture || ''}
            onBack={() => setActiveStep(REPORT_STEPS.REPORTING_METHOD)}
            onApply={handleApplyExcel}
            company={connection}
            templateId={selectedTemplate?.id}
            parameterValues={parameterValues?.parameters}
          />
        )}
        {activeStep === REPORT_STEPS.REPORT && (
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep !== REPORT_STEPS.REPORT && (
              <Button
                onClick={handleNext}
                variant="contained"
                endIcon={<ArrowNarrowLeft />}
                sx={{
                  padding: '0.45rem 1rem',
                  margin: '0',
                  textTransform: 'none',
                  borderRadius: '12px',
                  backgroundColor: '#0078C8',
                  '&:hover': { backgroundColor: '#0B789B' }
                }}
                size="large"
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            )}
          </Box>
        )}
      </Fragment>
    </>
  );
}

export default ReportWrraper;
